.BenchmarkList__header,
.BenchmarkList__footer {
  padding: 10px 20px;
  background: #f3f3f3;
  margin: 20px 40px;
  border-radius: 5px;
}

.BenchmarkList__container {
  width: calc(100vw - 80px);
  height: 500px;
  justify-content: center;
  margin: 0 40px;
}

.BenchmarkList__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.dateGeneration {
  text-align: right;
  margin: 20px 40px;
  font-style: italic;
  border-radius: 5px;
}
