.BenchmarkFilters {
  margin: 40px;
  padding: 20px;
  background: #eee;
}

.BenchmarkFilters__line {
  margin: 10px 0;
  display: flex;
}
.BenchmarkFilters__line label {
  width: 100px;
  font-weight: bold;
  text-align: right;
  margin-right: 20px;
}
