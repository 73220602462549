.BenchmarkGraph__title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 30px 0;
}

.BenchmarkGraph__type {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.BenchmarkGraph__graph {
  background: #efefef;
  padding: 20px;
  min-width: calc(50% - 60px);
  margin: 40px 0;
}

.BenchmarkGraph__nodata {
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: #bbb;
  font-size: 40px;
  padding: 90px 0;
}
