.Benchmark__container {
  width: calc(100vw - 80px);
  justify-content: center;
  margin: 0 40px;
}

.Benchmark__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.Benchmark__name {
  margin: 20px 0 10px 0;
  font-size: 24px;
  font-weight: bold;
}

.Benchmark__category {
  margin: 10px 0;
  font-size: 18px;
}

.Benchmark__date {
  margin: 10px 0;
  font-size: 12px;
  font-style: italic;
}
