.StatusIcon__successIcon,
.StatusIcon__partialSuccessIcon,
.StatusIcon__failIcon,
.StatusIcon__defaultStatusIcon {
  width: 16px;
  height: 16px;
  background: #70ee57;
  border-radius: 2px;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.7);
  margin: 0 auto;
}

.StatusIcon__successIcon {
  background: #70ee57;
}

.StatusIcon__partialSuccessIcon {
  background: #eea857;
}

.StatusIcon__failIcon {
  background: #ee5757;
}

.StatusIcon__defaultStatusIcon {
  background: rgb(63, 63, 63);
}
