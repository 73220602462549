.BenchmarksListHeader__categoryHeader {
  border-left: 1px solid #bbb;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  background: #fff8d8;
}

.BenchmarksListHeader__categoryHeader:nth-child(odd) {
  background: #ffec95;
}

.BaseTable__header-cell {
  border-left: 1px solid #ddd;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}
