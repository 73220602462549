body,
html {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

a {
  color: #493c00;
}
.filters {
  padding: 10px 20px;
  background: #f3f3f3;
  margin: 20px 40px;
}

div.dataTables_wrapper {
  width: 100%;
  margin: 0 auto;
}

#dataTable {
  border-collapse: collapse;
  border: 1px solid #000;
}

.dataTable {
  font-size: 12px;
  table-layout: fixed;
}

.firstHeader th {
  background: #ffec95;
  color: #000;
}
.firstHeader th:nth-child(odd) {
  background: #fff8d8;
}
table.dataTable thead .secondHeader th {
  box-sizing: border-box;
  height: 40px;
}
table.dataTable .information {
  width: 250px;
}
table.dataTable tbody tr:not(.dtrg-group) td {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

tbody tr:nth-child(even) td {
  background: #fff9dd;
}

.successIcon,
.partialSuccessIcon,
.failIcon,
.defaultStatusIcon {
  width: 16px;
  height: 16px;
  background: #70ee57;
  border-radius: 2px;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.7);
  margin: 0 auto;
}

.successIcon {
  background: #70ee57;
}

.partialSuccessIcon {
  background: #eea857;
}

.failIcon {
  background: #ee5757;
}

.defaultStatusIcon {
  background: rgb(63, 63, 63);
}
